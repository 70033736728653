<template>
  <div class="left">
    <div class="area">
      <div class="area_title">
        门禁设备分析
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;">
        <div class="pie_con">
          <div id="pie1" style="width: 100%;height: 200px;"></div>
          <div class="pie_num">98%</div>
          <div class="pie_label">在线率</div>
          <div class="label_container">
            <div class="lebal">
              <div class="label_icon" style="background-color: #FFF;"></div>
              <div class="label_text">总数</div>
              <div class="label_text">1225个</div>
              <div class="label_line"></div>
            </div>
            <div class="lebal">
              <div class="label_icon" style="background-color: #30D6C2;"></div>
              <div class="label_text" style="color: #30D6C2;">在线</div>
              <div class="label_text" style="color: #30D6C2;">1220个</div>
              <div class="label_line"></div>
            </div>
            <div class="lebal">
              <div class="label_icon" style="background-color: #4F4F4F;"></div>
              <div class="label_text">离线</div>
              <div class="label_text">5个</div>
              <div class="label_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChoseBuilding></ChoseBuilding>
  </div>
  <div class="right">
    <div class="area" style="height: 100%;position: relative;">
      <div class="area_title">
        门禁列表
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="search">
        <input type="text" v-model="devName" @input="findDev" placeholder="输入设备名称">
      </div>
      <div class="warn_title">
        <div class="warn_title_text" style="width: 32px;">序号</div>
        <div class="warn_title_text" style="width: 80px;">设备名称</div>
        <div class="warn_title_text" style="width: 80px;">设备状态</div>
        <div class="warn_title_text" style="width: 80px;">详细信息</div>
      </div>
      <div class="warn_container">
        <div v-for="(item,index) of devList" :key="index">
          <div class="warn_item" :class="index%2===0?'bg':''">
            <div class="warn_text" style="width: 32px;">{{ index + 1 }}</div>
            <div class="warn_text" style="width: 80px;" :style="{color:color[item.color]}">
              <div class="ract_left" :style="{backgroundColor:color[item.color]}"></div>
              <div class="ract_right" :style="{backgroundColor:color[item.color]}"></div>
              {{ item.name }}
            </div>
            <div class="warn_text" style="width: 80px;">{{ item.state ? "在线" : "离线" }}</div>
            <div class="warn_text" style="width: 80px;color: #30D6C2;cursor: pointer" @click="checkDev(item)">查看</div>
          </div>
          <div class="info" v-if="item.show">
            <div class="info_row">
              <div class="info_col">设备型号：{{ item.devtype }}</div>
              <div class="info_col">安装日期：{{ item.date }}</div>
            </div>
            <div class="info_row">
              <div class="info_col">地址：{{ item.isOutside ? "室外" : item.build + item.floor + item.room }}</div>
              <div class="info_col info_locate" @click="locateModel(item)"><div class="position_icon"></div>定位</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import ChoseBuilding from "./ChoseBuilding";
import Global from "@/js/global";
export default {
  name: "Illumination",
  components: {ChoseBuilding},
  data() {
    return {
      focus: false,
      name: "",
      devName: "",
      devList: [],
      allDevList: [],
      color: ["#D27D69", "#F8D8B0", "#30D6C2", "#BF79AF"],
      choseIndex: 1,
    };
  },
  mounted() {
    this.initPie1();
    this.getPoints();
  },
  unmounted() {
    if (window.myChart_1) {
      window.myChart_1.dispose();
      window.myChart_1 = null;
    }
  },
  methods: {
    getPoints() {
      let dev = Global.findEquipment("light");
      for (let item of dev) {
        item.date = "2022-10-08";
      }
      this.devList = dev;
      this.allDevList = [...dev];
    },
    findDev() {
      let arr = [];
      if (this.devName) {
        for (let dev of this.allDevList) {
          let i = dev.name.indexOf(this.devName);
          if (i > -1) {
            arr.push(dev)
          }
        }
      } else {
        arr = [...this.allDevList];
      }
      this.devList = arr;
    },
    checkDev(data){
      let flag = data.show
      for (let item of this.devList){
        item.show = false
      }
      data.show = !flag
    },
    choseLabel(data) {
      this.name = data.name;
      this.focus = false;
    },
    initPie1() {
      let container = document.getElementById("pie1");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["27%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 0.4,
              itemStyle: {
                color: "transparent"
              }
            },
              {
                value: 99.6,
                name: "rose2",
                itemStyle: {
                  color: "#30D6C2"
                }
              }
            ]
          },
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["27%", "50%"],
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 0.4,
              itemStyle: {
                color: "#4F4F4F"
              }
            },
              {
                value: 99.6,
                itemStyle: {
                  color: "transparent"
                }
              }
            ]
          },
          {
            type: "pie",
            radius: ["50%", "55%"],
            center: ["27%", "50%"],
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 0.4,
              itemStyle: {
                color: "#fff"
              }
            },
              {
                value: 99.6,
                itemStyle: {
                  color: "transparent"
                }
              }
            ]
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_1 = myChart_;
    },
    locateModel(data) {
      Global.choseEquipment(data);
    }
  }
};
</script>

<style scoped>
.info_col {
  font-size: 12px;
  color: #30D6C2;
}

.info {
  width: calc(100% - 20px);
  height: 60px;
  border: 1px solid #30D6C2;
  margin-left: 10px;
  background-color: rgba(14, 40, 37, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  justify-content: space-around;
}

.info_row {
  display: flex;
  justify-content: space-between;
}

.bg {
  background-image: linear-gradient(to right, rgba(88, 173, 169, 0.7) 0%, rgba(19, 21, 21, 0.1) 100%);
}

.search {
  position: absolute;
  right: 15px;
  top: -10px;
  width: 150px;
  height: 35px;
}

.search input {
  width: calc(100% - 30px);
  height: 100%;
  padding: 0 15px;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  background-color: transparent;
}

.warn_container {
  width: 100%;
  height: calc(100% - 100px);
  overflow: scroll;
}

.warn_item {
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  height: 27px;
  align-items: center;
  margin: 10px 0;
}

.warn_item .warn_text {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  position: relative;
}

.warn_title_text {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.warn_title {
  width: 100%;
  height: 37.5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #4CB0A4;
  /*background-image: radial-gradient(rgba(88, 173, 169, 0.6), rgba(19, 21, 21, 0.1));*/
}

.video_name {
  color: #fff;
}

.video {
  margin-bottom: 15PX;
}

.video_img {
  width: 170px;
  height: 96px;
  background-image: url(../assets/image/bg/video.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.video_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pie_num {
  width: 80px;
  height: 40px;
  color: #30D6C2;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 53px;
  top: 80px;
}

.pie_label {
  width: 100px;
  height: 25px;
  position: absolute;
  left: 43px;
  bottom: 3px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.label_line {
  width: 100%;
  bottom: 0;
  height: 1px;
  position: absolute;
  left: 0;
  background-image: linear-gradient(to right, rgba(144, 249, 252, 1) 0%, rgba(125, 125, 125, 0.4) 100%);
}

.label_text {
  margin-top: 4px;
  font-size: 14px;
  color: #ffffff;
}

.label_icon {
  width: 14px;
  height: 14px;
  margin-left: 6.5px;
  background-color: #fff;
}

.lebal {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 47px;
}

.label_container {
  position: absolute;
  width: 160px;
  right: 10px;
  top: 42px;
}

.pie_con {
  width: 100%;
  position: relative;
}

.chose_floor {
  background-image: url(../assets/image/bg/Group42.png) !important;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  color: #143F3A !important;
}

.floor_ {
  width: 96px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  color: #FFF;
  background-image: url(../assets/image/bg/Group33.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}

.floor {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.floors {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  height: 160px;
  overflow: scroll;
}

.whirling {
  transform: rotateZ(-180deg);
}

.select_:first-child {
  margin-top: 10px;
}

.select_:last-child {
  margin-bottom: 10px;
}

.select_ {
  padding: 15px 30px;
  color: #1EBCBF;
  cursor: pointer;
}

.select_con {
  position: absolute;
  width: 130%;
  height: 200px;
  left: 0;
  top: 50px;
  z-index: 2;
  background-color: #fff;
  border-radius: 5px;
  overflow: scroll;
  transition: all 0.3s;
}

.input_arrow {
  width: 21px;
  transition: all 0.3s;
  height: 16.5px;
  background-image: url(../assets/image/icon/arrow.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  right: 10px;
  top: 15px;
}

.b_input ._input {
  width: 100%;
  height: 100%;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  padding: 0 15px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  line-height: 46px;
  font-size: 18px;
}

.b_input {
  position: relative;
  width: 150px;
  height: 46px;
  margin-left: 20px;
}

.icon1 {
  background-image: url(../assets/image/icon/xuesheng.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon2 {
  background-image: url(../assets/image/icon/laoshi.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon3 {
  background-image: url(../assets/image/icon/xuexiao.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon4 {
  background-image: url(../assets/image/icon/shiyan.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon5 {
  background-image: url(../assets/image/icon/fanjian.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.overview_left {
  margin-right: 14px;
}

.text {
  font-size: 24px;
  color: #30D6C2;
  margin-bottom: 5px;
}

.text_tip {
  font-size: 14px;
  color: #F2F2F2;
}

.overview_icon {
  width: 20px;
  height: 20px;
}

.circle {
  width: 19px;
  height: 19px;
  margin-bottom: 10px;
  background-color: rgba(48, 204, 214, 0.3);
  border-radius: 50%;
}

.area_content {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0px;
}

.area_content_ {
  padding: 20px 10px;
  padding-bottom: 0px;
}

.overview {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 138px;
}
</style>
